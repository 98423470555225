<template>
  <footer class=" block md:flex justify-between text-xs py-5 px-14 bg-grey_dark text-white text-center md:text-left" v-if="!isProfileValid">
    <div class="">
      FRENTS 2024 (c) Все права защищены
    </div>
    <div class="block md:flex gap-x-16">
      <div>
        Политика конфиденциальности
      </div>
      <div>
        Публичная оферта
      </div>
    </div>
  </footer>
</template>
<script setup>
const { data } = useAuthState()

const isProfileValid = computed(() => {
    return data.value
        && (!data.value.first_name || !data.value.last_name || !data.value.email || !data.value.email || !data.value.role_client
            || (!data.value.link_vk && !data.value.link_instagram && !data.value.other_link)
        )
})
</script>

<style scoped lang="scss">
footer {
    @media (max-width: 768px) {
        padding-bottom: 70px;
    }
}
</style>
